@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.card-item-shop {
  display: flex;
  flex-direction: column;
  overflow: visible; // при нажатии по кнопке чтобы была выидна обводка
  gap: 6px;
  justify-self: stretch;
  @include fixedSize(100%, max-content); //305

  @include min-428-break {
    gap: 8px;
    @include fixedSize(100%, max-content); //345
  }

  @include min-744-break {
    @include fixedSize(100%, max-content); //305
  }
}

.item-rarity-card,
.item-rarity-shop-card {
  position: absolute;
}

.item-rarity-card,
.item-rarity-card-wrapper {
  @include singleFixedSize(100px);
}

.properties-slot {
  display: flex;
  width: 100%;
  z-index: 10;
  flex-wrap: wrap;
  min-height: max-content;
  max-height: max-content;
  @include min-428-break {
    min-height: max-content;
    max-height: max-content;
  }
}

.bottom-slot {
  margin-top: 0;

  @include min-428-break {
    margin-top: 4px;
  }

  @include min-744-break {
    margin-top: 0;
  }
}

.soldout-label {
  position: absolute;
  top: 1px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 200;
  opacity: 1;

  &:hover {
    opacity: 1;
  }
  background: url('../../../../../../public/assets/items/sold-out-label.webp')
    top center no-repeat;
  background-size: contain;

  @include fixedSize(70px, 56px);

  @include min-428-break {
    @include fixedSize(80px, 64px);
  }

  @include min-1024-break {
    @include fixedSize(60px, 48px);
  }

  @include min-1440-break {
    @include fixedSize(80px, 64px);
  }
}
