@use '@typography' as *;
@use '@fixed-size' as *;
@use '@queries' as *;

.card-offer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: var(--cst-cards-item-offer-background);
  outline: 2px solid var(--cst-cards-item-offer-outline);
  outline-offset: -2px;
  box-shadow: var(--elevation-1);
  overflow: hidden;
  border-radius: var(--cornerradius10);
  transition: all 0.2s ease;
  padding: 6px;

  @include fixedSize(114px, 88px);

  @include min-744-break {
    @include fixedSize(126px, 96px);
    border-radius: var(--cornerradius12);
  }

  span[id='btn-icon'] {
    position: absolute;
  }
  span[id='btn-icon'],
  svg[id='btn-icon'] {
    top: 6px;
    right: 6px;
    color: var(--global-grey700);
    @include singleFixedSize(16px);

    @include min-1440-break {
      @include singleFixedSize(18px);
    }
  }

  .tags-info-container {
    position: absolute;
    z-index: 3;
    gap: 4px;
    display: flex;
    flex-direction: column;

    bottom: 6px;
    left: 6px;

    .tag-info {
      padding: 2px 6px;
      height: 21px;
      border-radius: var(--cornerradius8);
      @include min-744-break {
        padding: 3px 8px;
        height: 24px;
        border-radius: var(--cornerradius8);
      }

      p {
        @include typography-xs;
        @include weight-medium;
      }
    }
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @include singleFixedSize(100%);
    @include min-744-break {
      @include singleFixedSize(100%);
    }

    .background {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      align-self: center;

      @include singleFixedSize(100%);
      @include min-744-break {
        @include singleFixedSize(100%);
      }
    }

    .item-image {
      z-index: 2;
      @include singleFixedSize(60px);
      @include min-1440-break {
        @include singleFixedSize(68px);
      }
    }
  }
}
