@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.info-block {
  display: flex;
  flex-direction: column;
  gap: 6px;
  z-index: 10;

  @include fixedSize(100%, 60px);

  @include min-428-break {
    @include fixedSize(100%, 66px);
  }

  @include min-744-break {
    @include fixedSize(100%, 60px);
  }

  .info-content {
    display: flex;
    flex-direction: column;
    width: 142px;
    @include min-428-break {
      width: 169px;
    }

    .info-title {
      color: var(--cst-cards-item-primary-title-default);
      width: inherit;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      overflow: hidden;

      transition: all 0.2s ease;
      &:hover {
        color: var(--cst-cards-item-primary-descriptiontext-hover);
      }

      @include typography-xs;
      @include weight-medium;

      @include min-428-break {
        @include typography-base;
      }
      @include min-744-break {
        @include typography-s;
      }
    }

    .info-age {
      color: var(--cst-cards-item-primary-type-text-default);
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      overflow: hidden;
      @include typography-xs;
      @include weight-medium;

      @include min-428-break {
        @include typography-base;
      }
      @include min-744-break {
        @include typography-s;
      }
    }

    .info-price-tags {
      display: flex;
      width: 100%;
      gap: 6px;
      align-items: center;

      .info-price-current {
        color: var(--cst-cards-item-primary-newpricetext-default);

        @include typography-m;
        @include weight-bold;

        @include min-428-break {
          @include typography-xl;
        }

        @include min-744-break {
          @include typography-l;
        }
      }

      .info-price-old {
        color: var(--cst-cards-item-primary-oldpricetext-default);
        @include typography-s;
        @include weight-semi-bold;
      }
    }
  }
}
