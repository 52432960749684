@use '@fixed-size' as *;
@use '@fixed-size' as *;
@use '@button' as *;
@use '@queries' as *;

.card-live-feed {
  display: flex;
  justify-content: center;
  border-radius: var(--cornerradius10);
  background: var(--cst-cards-live-feed-background-default);
  outline: 2px solid var(--cst-cards-live-feed-outline-default);
  outline-offset: -2px;
  box-shadow: var(--elevation-1);
  transition: all 0.12s ease-in-out;

  @include fixedSize(84px, 58px);

  @include min-428-break {
    @include fixedSize(114px, 88px);
  }

  @include min-744-break {
    @include fixedSize(126px, 96px);
    border-radius: var(--cornerradius12);
  }

  &--content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    justify-content: center;
    gap: 10px;
    position: relative;

    &__item {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.item-rarity {
  position: absolute;
  z-index: 0;

  &-adopt {
    @include singleFixedSize(58px);
    padding: 5.8px 9.28px;

    @include min-428-break {
      @include singleFixedSize(72px);
      padding: 7.2px 11.52px;
    }

    @include min-744-break {
      @include singleFixedSize(78px);
      padding: 7.8px 12.48px;
    }
  }

  &-mm2 {
    @include singleFixedSize(68px);
    padding: 5.8px 9.28px;

    @include min-428-break {
      @include singleFixedSize(92px);
      padding: 7.2px 11.52px;
    }

    @include min-744-break {
      @include singleFixedSize(108px);
      padding: 7.8px 12.48px;
    }
  }
}

.item {
  pointer-events: none;
  z-index: 1;
  @include singleFixedSize(42px);

  @include min-428-break {
    @include singleFixedSize(60px);
  }

  @include min-744-break {
    @include singleFixedSize(68px);
  }
}

.btn-info {
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  position: absolute;
  top: 3.5px;
  right: 6px;

  @include min-428-break {
    top: 7px;
    right: 7px;
  }

  .icon-info {
    @include singleFixedSize(12px);

    @include min-428-break {
      @include singleFixedSize(16px);
    }

    @include min-744-break {
      @include singleFixedSize(18px);
    }
  }
}
