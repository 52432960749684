@use '@queries' as *;
@use '@fixed-size' as *;
@use '@typography' as *;

.item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 2px var(--cst-cards-item-primary-outline-default);
  border-radius: var(--cornerradius12);

  aspect-ratio: 1 /1;

  @include fixedSize(100%, 100%);

  .item-shop {
    z-index: 1;
    pointer-events: none;

    @include singleFixedSize(68px);

    @include min-428-break {
      @include singleFixedSize(96px);
    }

    @include min-428-break {
      @include singleFixedSize(92px);
    }
  }
}

.tags-info-container {
  position: absolute;
  z-index: 3;
  gap: 4px;
  display: flex;
  flex-direction: column;

  bottom: 12px;
  left: 12px;

  .tag-info {
    padding: 3.5px 6px;
    height: 22px;
    border-radius: var(--cornerradius6);
    @include min-428-break {
      padding: 3.5px 8px;
      height: 25px;
      border-radius: var(--cornerradius8);
    }

    @include min-744-break {
      padding: 3.5px 6px;
      height: 22px;
      border-radius: var(--cornerradius6);
    }

    p {
      @include typography-xs;
      @include weight-medium;

      @include min-428-break {
        @include typography-s;
      }
    }
  }
}

.fav-btn-container {
  position: absolute;
  transition: all 0.2s ease-in-out;

  gap: 4px;
  display: flex;
  flex-direction: column;
  top: 8px;
  right: 8px;

  @include min-428-break {
    top: 12px;
    right: 12px;
  }
}
